import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Strategies `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-vix-spritz/"
            }}>{`VIX Spritz`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-rain-or-shine/"
            }}>{`Rain or Shine`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: mean-variance optimization`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking stock markets, treasuries, gold`}</li>
        <li parentName="ul">{`Rebalancing schedule: monthly`}</li>
        <li parentName="ul">{`Taxation: 90% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $3,000`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`Straight Four`}</em>{` aims to continually beat the 60/40 benchmark while at the same time offering lower drawdowns. The strategy uses mean-variance optimization to shift its asset allocation between stock market indices, treasuries, and gold. `}<em parentName="p">{`Straight Four`}</em>{` rebalances monthly, typically adjusting the weights of only three ETFs. This schedule equates to meager maintenance requirements, appealing to investors with a busy lifestyle.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Straight Four: A mean-variance optimization strategy",
                "title": "TuringTrader.com, Straight Four: A mean-variance optimization strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/li-universal-investment-strategy/"
            }}>{`Universal Investment Strategy`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Straight Four`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`trade ETFs tracking the S&P 500, the NASDAQ, long-term U.S. Treasuries, and gold`}</li>
        <li parentName="ul">{`evaluate the performance of various asset weights over a lookback period of approximately three months`}</li>
        <li parentName="ul">{`reduce exposure based on historical volatility`}</li>
        <li parentName="ul">{`rebalance once per month, picking the combination with the best modified Sharpe Ratio`}</li>
      </ul>
      <p><em parentName="p">{`Straight Four`}</em>{` is a close relative to LogicalInvest's `}<em parentName="p">{`Universal Investment Strategy`}</em>{`. We have enhanced the strategy by adding more asset classes and reducing risk exposure through a volatility-overlay.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`Straight Four`}</em>{` diversifies across three major asset classes. This diversification manifests itself in a beta of around 0.4. Even in bullish periods, the portfolio rarely invests much more than 60% in the stock market, keeping the portfolio balanced and well-mannered.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p><em parentName="p">{`Straight Four`}</em>{` beats the 60/40 benchmark in many years and offers additional upside in times of recession. Further, the portfolio wins over the S&P 500 when contemplating the full economic cycle.`}</p>
      <p>{`The chart above shows how `}<em parentName="p">{`Straight Four`}</em>{`'s rolling returns exceed those of the 60/40 portfolio in most periods. As a result, `}<em parentName="p">{`Straight Four`}</em>{` continually expands its lead over the benchmark.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`Straight Four`}</em>{` trades frequently and regularly triggers taxable events. The portfolio rarely holds assets long enough to qualify for long-term treatment of capital gains. With these properties, the overall tax-burden is undoubtedly higher than for a passive 60/40 portfolio. However, because of its significantly higher returns, `}<em parentName="p">{`Straight Four`}</em>{` may still add value to taxable accounts.`}</p>
      <p><em parentName="p">{`Straight Four`}</em>{` invests in no more than three ETFs at a time. The strategy should function as intended with as little as $3,000 of capital.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, December 2020`}</a>{`: Initial release.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      